<template>
    <div class="si-chart-view-page" v-loading="loading">
        <div class="si-chart-view-header">
            <div class="si-chart-view-header-left">
                <div class="si-header-title">Search</div>
                <div class="si-chart-view-date">{{ date }}</div>
            </div>
            <div class="si-chart-view-header-right">
                <el-checkbox
                    v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'"
                    class="si-chart-view-historical"
                    v-model="timeline_settings.historical"
                    :disabled="disabled_historical">
                    Historical Data
                </el-checkbox>
                <div :class="['si-chart-view-datepicker', {'si-chart-view-datepicker-hidden': !disabled_date_picker}]">
                    <period-picker
                        :period_from="timeline_settings.period[0]"
                        :period_to="timeline_settings.period[1]"
                        @change="change_period"
                    />
                </div>
                <el-button class="si-chart-view-back-btn" @click="back_to_filters">
                    <svg-icon icon-class="chevron_big_left" />
                    Edit filters
                    <div class="si-chart-view-filters-counter">
                        {{this.filters.length}}
                    </div>
                </el-button>
            </div>
        </div>

        <div class="si-chart-view-controls">
            <table>
                <thead>
                    <tr>
                        <th>Chart type</th>
                        <th v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">X axis</th>
                        <th v-if="chart_settings.chart_type">Y axis</th>
                        <th v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">Bubble size</th>
                        <th v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">Bubble color</th>
                        <th v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">Regression line</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <el-select v-model="chart_settings.chart_type" placeholder="chart type" size="mini" @change="change_chart_type">
                                <el-option label="Bubble" value="bubble"></el-option>
                                <el-option label="Line" value="line"></el-option>
                            </el-select>
                        </td>
                        <td v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">
                            <el-select v-model="chart_settings.xaxis" placeholder="x axis" size="mini">
                                <el-option
                                    :label="item.app_name"
                                    :value="item.orkl_name"
                                    v-for="item in user_fields"
                                    :key="item.orkl_name"
                                    v-if="['integer', 'float'].includes(item.field_type)"
                                ></el-option>
                            </el-select>
                        </td>
                        <td v-if="chart_settings.chart_type">
                            <el-select v-model="chart_settings.yaxis" placeholder="y axis" size="mini">
                                <el-option
                                    :label="item.app_name"
                                    :value="item.orkl_name"
                                    v-for="item in user_fields"
                                    :key="item.app_name"
                                    v-if="['integer', 'float'].includes(item.field_type)"
                                ></el-option>
                            </el-select>
                        </td>
                        <td v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">
                            <el-select v-model="chart_settings.sized_by" placeholder="bubble size" size="mini" clearable>
                                <el-option
                                    :label="item.app_name"
                                    :value="item.orkl_name"
                                    v-for="item in user_fields"
                                    :key="item.app_name"
                                    v-if="['integer', 'float'].includes(item.field_type)"
                                ></el-option>
                            </el-select>
                        </td>
                        <td v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">
                            <el-select v-model="chart_settings.colored_by" placeholder="bubble color" size="mini" clearable>
                                <el-option
                                    :label="item.app_name"
                                    :value="item.orkl_name"
                                    v-for="item in user_fields"
                                    :key="item.app_name"
                                    v-if="['integer', 'float'].includes(item.field_type)"
                                ></el-option>
                            </el-select>
                        </td>
                        <td v-if="chart_settings.chart_type && chart_settings.chart_type === 'bubble'">
                            <el-select v-model="chart_settings.regression_line_type" placeholder="regression line" size="mini" clearable>
                                <el-option label="Exponential" value="exponential"></el-option>
                                <el-option label="Linear" value="linear"></el-option>
                                <el-option label="Polynomial" value="polynomial"></el-option>
                            </el-select>
                        </td>
                        <td>
                            <div class="si-chart-view-btn">
                                <el-button size="mini" class="uppercased" @click="make_chart" type="primary" :disabled="!can_make_chart">Make chart</el-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="si-chart-view-body">
            <div v-observe-visibility="resize_chart_if_visible" class="si-chart-view-chart" :id="chart_id"></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import * as echarts from 'echarts'
import * as ecStat from 'echarts-stat'
import ChartTooltip from '@/views/SearchISIN/ChartView/ChartTooltip'
import chart from '@/mixins/chart'
import themed_chart from '@/mixins/themed_chart'
import PeriodPicker from "@/components/PageComponents/PeriodPicker";
import {date_to_string, date_to_moment, debounce, num_to_money} from '@/utils'

echarts.registerTransform(ecStat.transform.regression);

export default {
    mixins: [chart, themed_chart],

    components: {PeriodPicker},

    props: {
        visible: {
            type     : Boolean,
            default () { return false }
        },
    },
    data() {
        return {
            asset        : undefined,
            filters      : [],
            date         : undefined,
            fields       : [],
            chart        : null,
            base_chart_id: 'si-chart-view-chart-n',
            chart_id: '',

            chart_settings: {
                chart_type: 'bubble',
                xaxis: '',
                yaxis: '',
                regression: undefined,
                sized_by: undefined,
                colored_by: undefined,
                regression_line_type: undefined,
            },

            timeline_settings: {
                historical: false,
                period: [
                    this.$moment().add({months:-3}).toDate(),
                    this.$moment().toDate()
                ],
            },


            grid_id:  "searchIsinChart" + Date.now(),
            chart_dom: null,
            user_fields: [],
            max_size: 0,
            max_color: 0,
            min_color: 0,
            size_index: null,
            color_index: null,

            xa_name: undefined,
            ya_name: undefined,
            size_name: undefined,
            color_name: undefined,
            view_data: [],
            loading: false,
        }
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },

    computed: {
        can_make_chart(){
            if (this.chart_settings.chart_type === 'bubble' && this.chart_settings.xaxis && this.chart_settings.yaxis && !this.timeline_settings.historical)
                return true;
            if (this.chart_settings.chart_type === 'bubble' && this.chart_settings.xaxis && this.chart_settings.yaxis && this.timeline_settings.historical && this.timeline_settings.period.length)
                return true;
            if (this.chart_settings.chart_type === 'line' && this.chart_settings.yaxis && this.timeline_settings.historical && this.timeline_settings.period.length)
                return true;
            return false
        },

        disabled_historical(){
            return this.chart_settings.chart_type && this.chart_settings.chart_type === 'line'
        },

        disabled_date_picker(){
            if(this.chart_settings.chart_type === 'line')
                return true

            if(this.timeline_settings.historical)
                return true

            return false
        },

        bubble_xaxis(){
            return {
                splitLine: this.scatter_style.splitLine,
                splitArea: this.scatter_style.splitArea,
                scale: true,
                name: this.xa_name,
                nameLocation: 'middle',
                nameGap: 25,
                axisLabel: this.scatter_style.xAxis.axisLabel
            }
        },
        bubble_yaxis(){
            return {
                splitLine: this.scatter_style.splitLine,
                splitArea: this.scatter_style.splitArea,
                scale: true,
                name: this.ya_name,
                nameLocation: 'end',
                nameGap: 20,
                axisLabel: this.scatter_style.yAxis.axisLabel
            }
        },
        line_yaxis(){
            return {
                splitLine: this.scatter_style.splitLine,
                splitArea: this.scatter_style.splitArea,
                scale: true,
                name: this.ya_name,
                nameLocation: 'end',
                nameGap: 20,
                axisLabel: this.scatter_style.yAxis.axisLabel,
            }
        }
    },

    mounted(){
        this.chart_id = this.base_chart_id + Math.floor(Math.random() * (10000 - 99999 + 1) + 10000)
    },

    methods: {
        resize_chart_if_visible(){
            if(this.chart){
                this.loading = true
                this.chart.resize()
                this.loading = false
            }
        },

        back_to_filters(){
            this.$router.push('/searchisin/')
        },

        change_period($event){
            this.timeline_settings.period[0] = $event[0];
            this.timeline_settings.period[1]   = $event[1];
        },

        init_chart(params) {
            this.asset = params.asset
            if (params.date) {
                this.date = this.$moment(params.date).format('YYYY-MM-DD')
            }

            //load filters
            if(params.filters) {
                this.$store.dispatch('searchIsin/searchIsinFields', this.asset).then((fields_response) => {
                    this.fields = fields_response;

                    let filters = params.filters.filter(f => {
                        return f.apply
                    })
                    filters.forEach(f => {
                        delete f.has_errors;
                        delete f.count_result;

                        let field = this.fields.find(fld => fld.orkl_name === f.field)
                        if (['number', 'float'].includes(field.field_type) && !["IS EMPTY", "IS NOT EMPTY", "IN RANGE"].includes(f.logics)) {
                            f.value *= 1
                        } else if (['number', 'float'].includes(field.field_type) && ["IN RANGE"].includes(f.logics)) {
                            f.value_list.forEach(vl => {
                                vl *= 1
                            })
                        }
                    })
                    this.filters = filters
                })
            }

            this.user_fields = params.fields
        },

        change_chart_type(){
            if (this.chart_settings.chart_type && this.chart_settings.chart_type === 'line')
                this.$set(this.timeline_settings, 'historical', true)
        },

        make_chart(){
            if (this.chart){
                this.chart.dispose()
                this.chart = null
            }

            this.view_data = []

            this.chart_dom = document.getElementById(this.chart_id);

            if (this.chart_settings.xaxis){
                let xa_index = this.user_fields.findIndex(x => x.orkl_name === this.chart_settings.xaxis);
                this.xa_name = this.user_fields[xa_index].app_name
            }

            let ya_index = this.user_fields.findIndex(x => x.orkl_name === this.chart_settings.yaxis);
            this.ya_name = this.user_fields[ya_index].app_name

            if (this.chart_settings.sized_by){
                let size_index = this.user_fields.findIndex(x => x.orkl_name === this.chart_settings.sized_by);
                this.size_name = this.user_fields[size_index].app_name
            }

            if (this.chart_settings.colored_by){
                let color_index = this.user_fields.findIndex(x => x.orkl_name === this.chart_settings.colored_by);
                this.color_name = this.user_fields[color_index].app_name
            }

            this.min_color = 0
            this.max_color = 0
            this.max_size = 0

            this.loading = true

            if (this.chart_settings.chart_type === 'bubble' &&  !this.timeline_settings.historical)
                this.make_bubble_day_chart();
            if (this.chart_settings.chart_type === 'bubble' &&  this.timeline_settings.historical)
                this.make_bubble_history_chart();
            if (this.chart_settings.chart_type === 'line')
                this.make_line_chart();
        },

        make_line_chart(){
            let $this = this

            let params = {
                asset: this.asset,
                date: this.date,
                filters: this.filters
            }

            this.$store.dispatch('searchIsin/searchIsinResult', params).then((isin_items) => {
                let isins = []
                isin_items.map(item => isins.push(item.isin))

                let history_params = {
                    asset: $this.asset,
                    date_from: $this.timeline_settings.period[0],
                    date_to: $this.timeline_settings.period[1],
                    isins: isins,
                    fields: ['isin', 'source_date', $this.chart_settings.yaxis]
                }

                this.$store.dispatch('searchIsin/searchIsinResultHistory', history_params).then((items) => {

                    $this.chart = echarts.init($this.chart_dom, 'macarons')

                    let dataset = []
                    let series = []

                    let datahash = {}

                    items.map(item => {
                        //todo: restricted lines count
                        if(Object.keys(datahash).length > 100) return
                        let category = date_to_string(item.source_date, 'DD MMM YY')
                        let legend = item.isin
                        let value = item[$this.chart_settings.yaxis]
                        if (!datahash[legend]) datahash[legend] = []
                        datahash[legend].push([category, value])
                    })

                    Object.keys(datahash).forEach((legend, index) => {
                        dataset.push({
                            source: datahash[legend],
                            dimensions: ['Category', 'Value']
                        })
                        series.push({
                            name: legend,
                            type: 'line',
                            encode: {
                                x: 'Category',
                                y: 'Value'
                            },
                            datasetIndex: index,
                            emphasis: {
                                focus: 'series'
                            },
                            animationDelay: function (idx) {
                                return idx * 10;
                            }
                        })
                    })

                    let options = {
                        xAxis: {
                            splitLine: {
                                lineStyle: {
                                    type: 'dashed'
                                },
                            },
                            scale: true,
                            name: 'Date',
                            nameLocation: 'middle',
                            nameGap: 25,
                            nameTextStyle: {
                                //color: '#4b74ff',
                                fontStyle: 'italic',
                            },
                            type: 'category',
                        },
                        yAxis: $this.line_yaxis,
                        series  : series,
                        dataset : dataset,
                        animationEasing: 'elasticOut',
                        tooltip: {
                            axisPointer: {
                                type: 'shadow'
                            },
                            backgroundColor: this.line_chart_settings[this.current_theme].bg_colors[1],
                            borderColor: this.line_chart_settings[this.current_theme].bg_colors[1],
                            textStyle: {
                                color: this.line_chart_settings[this.current_theme].label_color
                            }
                        },
                        grid: {
                            containLabel: true
                        },
                    }

                    $this.chart.setOption(options)
                    $this.loading = false
                });
            });
        },

        make_bubble_history_chart(){
            let $this = this

            let params = {
                asset: this.asset,
                date: this.date,
                filters: this.filters
            }

            this.$store.dispatch('searchIsin/searchIsinResult', params).then((isin_items) => {
                let isins = []
                let names_for_tooltip = {}
                isin_items.map(item => {
                    isins.push(item.isin)
                    names_for_tooltip[item.isin] = item.name
                })


                let history_params = {
                    asset: $this.asset,
                    date_from: $this.timeline_settings.period[0],
                    date_to: $this.timeline_settings.period[1],
                    isins: isins,
                    fields: [
                        'isin',
                        'source_date',
                        'name',
                        $this.chart_settings.yaxis,
                        $this.chart_settings.xaxis,
                    ]
                }

                if($this.chart_settings.colored_by && !history_params.fields.includes($this.chart_settings.colored_by))
                    history_params.fields.push($this.chart_settings.colored_by)

                if($this.chart_settings.sized_by && !history_params.fields.includes($this.chart_settings.sized_by))
                    history_params.fields.push($this.chart_settings.sized_by)

                this.$store.dispatch('searchIsin/searchIsinResultHistory', history_params).then((items) => {

                    let series_data   = []
                    let isins_hash    = {}
                    let timeline_hash = {}
                    let series = []
                    let datahash = {}
                    let dataset = []

                    let minX = 0;
                    let maxX = 0;
                    let minY = 0;
                    let maxY = 0;

                    //group items by date
                    let grouped_by_source_date_items = items.reduce(function (r, a) {
                        r[a.source_date.$date] = r[a.source_date.$date] || [];
                        r[a.source_date.$date].push(a);
                        return r;
                    }, Object.create(null));

                    //sort object by date property
                    grouped_by_source_date_items = Object.keys(grouped_by_source_date_items).sort().reduce(
                        (obj, key) => {
                            obj[key] = grouped_by_source_date_items[key];
                            return obj;
                        },
                        {}
                    );

                    //generate bubbles
                    for(let date in grouped_by_source_date_items){
                        let row = []
                        grouped_by_source_date_items[date].map((item) => {

                            if(item[$this.chart_settings.xaxis] < minX)
                                minX = Math.ceil(item[$this.chart_settings.xaxis])

                            if(item[$this.chart_settings.xaxis] > maxX)
                                maxX = Math.ceil(item[$this.chart_settings.xaxis])

                            if(item[$this.chart_settings.yaxis] < minY)
                                minY = Math.ceil(item[$this.chart_settings.yaxis])

                            if(item[$this.chart_settings.yaxis] > maxY)
                                maxY = Math.ceil(item[$this.chart_settings.yaxis])

                            let isin_date = date_to_string(item.source_date, 'DD MMM YY')
                            isins_hash[item.isin] = 1
                            timeline_hash[isin_date] = 1

                            let size_val = 0
                            let color_val = 0
                            if ($this.chart_settings.sized_by) {
                                size_val = item[$this.chart_settings.sized_by];
                                $this.max_size = Math.max($this.max_size, size_val);
                            }

                            if ($this.chart_settings.colored_by) {
                                color_val = item[$this.chart_settings.colored_by];
                                $this.min_color = Math.min($this.min_color, color_val);
                                $this.max_color = Math.max($this.max_color, color_val);
                            }

                            let dataset_id = `dataset_${item.isin}_bubble`

                            //object style way, doesn't works with regression
                            /*row.push({
                                id: dataset_id,
                                [$this.chart_settings.xaxis]: item[$this.chart_settings.xaxis],
                                [$this.chart_settings.yaxis]: item[$this.chart_settings.yaxis],
                                'isin': item.isin,
                                'name': item.isin,
                                size_val,
                                color_val
                            })

                            this.size_index = 'size_val'
                            this.color_index = 'color_val'*/

                            //array style way
                            row.push([
                                //dataset_id,
                                item[$this.chart_settings.xaxis],
                                item[$this.chart_settings.yaxis],
                                item.isin,
                                names_for_tooltip[item.isin],
                                size_val,
                                color_val
                            ])
                            this.size_index = 4
                            this.color_index = 5
                        })
                        series_data.push(row)
                    }

                    $this.chart = echarts.init($this.chart_dom, 'macarons')

                    let isins_arr = Object.keys(isins_hash)
                    let timeline_arr = Object.keys(timeline_hash)

                    $this.bubble_yaxis.min = minY
                    $this.bubble_yaxis.max = maxY
                    $this.bubble_xaxis.min = minX
                    $this.bubble_xaxis.max = maxX

                    //generate trails
                    /*$this.history_list.forEach((date_data) => {
                        date_data.forEach((item, index) => {
                            let legend = item.isin
                            let dataset_id = `dataset_${legend}_line`
                            if (!datahash[legend]) datahash[legend] = []
                            datahash[legend].push({
                                id: dataset_id,
                                [$this.chart_settings.xaxis]: item[$this.chart_settings.xaxis],
                                [$this.chart_settings.yaxis]: item[$this.chart_settings.yaxis],
                            })
                        })
                    })*/

                    /*Object.keys(datahash).forEach((legend, index) => {
                        dataset.push({
                            source: datahash[legend],
                            dimensions: [$this.chart_settings.xaxis, $this.chart_settings.yaxis]
                        })
                        series.push({
                            name: legend,
                            type: 'line',
                            encode: {
                                x: $this.chart_settings.xaxis,
                                y: $this.chart_settings.yaxis
                            },
                            xAxisIndex: 0,
                            yAxisIndex: 0,
                            showSymbol: false,
                            datasetIndex: index,
                            animation: true,
                        })
                    })*/

                    dataset.push({ source: series_data[0] })
                    series.push({
                        type: 'scatter',
                        itemStyle: this.scatter_style.itemStyle,
                        datasetIndex: dataset.length - 1,
                        symbolSize: function (data) {
                            return $this.symbol_size_function(data[$this.size_index]);
                        },
                        /*dimensions: [
                            'id',
                            $this.chart_settings.xaxis,
                            $this.chart_settings.yaxis,
                            'isin',
                            'name',
                            'size_val',
                            'color_val'
                        ],*/
                        encode: {
                            x: $this.chart_settings.xaxis,
                            y: $this.chart_settings.yaxis
                        },
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                    })

                    let option = {
                        baseOption: {
                            dataset: dataset,
                            xAxis: [
                                $this.bubble_xaxis
                            ],
                            yAxis: [
                                $this.bubble_yaxis
                            ],
                            grid: {
                                right: 60,
                                left: 60
                            },
                            series: series,
                            animationDurationUpdate: 1200,
                            animationEasingUpdate: 'linear',
                            animationEasing: 'linear',
                            timeline: {
                                axisType: 'category',
                                autoPlay: true,
                                playInterval: 1000,
                                loop: false,
                                symbol: 'circle',
                                checkpointStyle: {
                                    borderWidth: 2
                                },
                                controlStyle: {
                                    showNextBtn: false,
                                    showPrevBtn: false
                                },
                                data: [],
                                controlPosition: 'right',
                                right: 5,
                                left: 5,
                                bottom: -5
                            },
                            tooltip: $this.tooltip_function(true),
                            visualMap: $this.colorize_function(isins_arr, 2),
                            title: [{
                                text: timeline_arr[0],
                                textAlign: 'center',
                                right: 50,
                                top: '2%',
                                textStyle: {
                                    fontSize: 30
                                }
                            }]
                        },
                        options: []
                    }

                    if ($this.chart_settings.regression_line_type) {
                        dataset.push({
                            transform: {
                                type: 'ecStat:regression',
                                config: { method: $this.chart_settings.regression_line_type }
                            }
                        })

                        option.baseOption.series.push({
                            name: 'line',
                            type: 'line',
                            datasetIndex: 1,
                            symbolSize: 0.1,
                            symbol: 'circle',
                            /*dimensions: [
                                'id',
                                $this.chart_settings.xaxis,
                                $this.chart_settings.yaxis,
                                'isin',
                                'name',
                                'size_val',
                                'color_val'
                            ],*/
                            label: { show: false, fontSize: 16 },
                            labelLayout: { dx: -20 },
                            encode: { label: 2, tooltip: 1 }
                        })
                    }

                    for (let n = 0; n < timeline_arr.length; n++) {
                        option.baseOption.timeline.data.push(timeline_arr[n]);

                        let option_dataset = []
                        let option_series = []

                        option_dataset.push({  source: series_data[n] })
                        option_series.push({
                            name: timeline_arr[n],
                            type: 'scatter',
                            itemStyle: this.scatter_style.itemStyle,
                            datasetIndex: 0,
                            /*dimensions: [
                                'id',
                                $this.chart_settings.xaxis,
                                $this.chart_settings.yaxis,
                                'isin',
                                'name',
                                'size_val',
                                'color_val'
                            ],*/
                            symbolSize: function (data) {
                                return $this.symbol_size_function(data[$this.size_index]);
                            },
                            encode: {
                                x: $this.chart_settings.xaxis,
                                y: $this.chart_settings.yaxis
                            }
                        })

                        /*Object.values(datahash).forEach((item, index) => {
                            option_dataset.push({
                                source: item,
                                dimensions: [$this.chart_settings.xaxis, $this.chart_settings.yaxis]
                            })
                            option_series.push({
                                name: index + 'testadsasd',
                                type: 'line',
                                encode: {
                                    x: $this.chart_settings.xaxis,
                                    y: $this.chart_settings.yaxis
                                },
                                xAxisIndex: 0,
                                yAxisIndex: 0,
                                showSymbol: false,
                                animation: true,
                            })
                        })*/

                        if ($this.chart_settings.regression_line_type) {
                            option_dataset.push({
                                transform: {
                                    type: 'ecStat:regression',
                                    config: { method: $this.chart_settings.regression_line_type }
                                }
                            })
                            option_series.push({
                                name: 'line',
                                type: 'line',
                                datasetIndex: 1,
                                symbolSize: 0.1,
                                symbol: 'circle',
                                label: { show: false, fontSize: 16 },
                                labelLayout: { dx: -20 },
                                encode: { label: 2, tooltip: 1 }
                            })
                        }

                        option.options.push({
                            title: {
                                show: true,
                                'text': timeline_arr[n] + ''
                            },
                            dataset: option_dataset,
                            series: option_series
                        });
                    }

                    $this.chart.setOption(option)

                    $this.loading = false
                })

            })
        },

        make_bubble_day_chart(){
            let $this = this

            let params = {
                asset: this.asset,
                date: this.date,
                filters: this.filters
            }

            this.$store.dispatch('searchIsin/searchIsinResult', params).then((items) => {
                $this.chart = echarts.init($this.chart_dom, 'macarons')

                let series_data = []
                let isins_arr = []

                items.forEach(item => {
                    isins_arr.push(item.isin)

                    let size_val = 0
                    let color_val = 0

                    if ($this.chart_settings.sized_by) {
                        size_val = item[$this.chart_settings.sized_by];
                        $this.max_size = Math.max($this.max_size, size_val);
                    }

                    if ($this.chart_settings.colored_by) {
                        color_val = item[$this.chart_settings.colored_by];
                        $this.min_color = Math.min($this.min_color, color_val);
                        $this.max_color = Math.max($this.max_color, color_val);
                    }

                    //object style way, doesn't works with regression
                    /*series_data.push({
                        [$this.chart_settings.xaxis]: item[$this.chart_settings.xaxis],
                        [$this.chart_settings.yaxis]: item[$this.chart_settings.yaxis],
                        'isin': item.isin,
                        'name': item.name,
                        size_val,
                        color_val
                    })
                    this.size_index = 'size_val'
                    this.color_index = 'color_val'
                    */

                    //array style way
                    series_data.push([
                        item[$this.chart_settings.xaxis],
                        item[$this.chart_settings.yaxis],
                        item.isin,
                        item.name,
                        size_val,
                        color_val
                    ])

                    this.size_index = 4
                    this.color_index = 5


                    let view_row = {  'isin': item.isin }
                    view_row[$this.xa_name] = item[$this.chart_settings.xaxis]
                    view_row[$this.ya_name] = item[$this.chart_settings.yaxis]
                    if ($this.size_name) {
                        view_row[$this.size_name] = size_val
                    }
                    if ($this.color_name) {
                        view_row[$this.color_name] = color_val
                    }

                    $this.view_data.push(view_row)
                })

                let dataset = [{
                    source: series_data
                }]
                let series = [{
                    type: 'scatter',
                    datasetIndex: 0,
                    itemStyle: this.scatter_style.itemStyle,
                    symbolSize: function (data) {
                        return $this.symbol_size_function(data[$this.size_index]);
                    },
                    /*dimensions: [
                        $this.chart_settings.xaxis,
                        $this.chart_settings.yaxis,
                        'isin',
                        'name',
                        'size_val',
                        'color_val'
                    ],*/
                    encode: {
                        x: $this.chart_settings.xaxis,
                        y: $this.chart_settings.yaxis
                    }
                }]

                if ($this.chart_settings.regression_line_type) {
                    dataset.push({
                        transform: {
                            type: 'ecStat:regression',
                            config: { method: $this.chart_settings.regression_line_type },
                        },
                    })

                    series.push({
                        name: 'line',
                        type: 'line',
                        datasetIndex: 1,
                        symbolSize: 0.1,
                        itemStyle: this.regression_style.itemStyle,
                        symbol: 'circle',
                        label: { show: false, fontSize: 16 },
                        labelLayout: { dx: -20 },
                        encode: { label: 2, tooltip: 1 }
                    })
                }

                let options = {
                    dataset: dataset,
                    xAxis: $this.bubble_xaxis,
                    yAxis: $this.bubble_yaxis,
                    tooltip: $this.tooltip_function(),
                    series: series,
                    visualMap: $this.colorize_function(isins_arr, 2)
                }

                $this.chart.setOption(options)

                $this.loading = false
            });
        },

        tooltip_function(include_date){
            let $this = this
            return {
                position: () => 'top',
                enterable: true,
                renderMode: 'html',
                appendToBody: true,
                className: 'si-chart-view-tooltip',
                formatter: (params) => {
                    if (params.componentType && params.componentType === 'timeline') {
                        //return params.name
                        return params[4]
                    }
                    else {
                        /*let fields = this.user_fields.filter(item =>
                            !['isin', 'name'].includes(item.field) && Object.keys(params.value).includes(item.field)
                        )*/

                        let ChartTooltipComponentClass = Vue.extend(ChartTooltip)
                        let tooltipInfo = new ChartTooltipComponentClass({
                            propsData: {
                                //data: params.value,
                                data: {
                                    isin: params.value[2],
                                    name: params.value[3],
                                },
                                //fields: fields,
                                asset: this.asset
                            }
                        })
                        tooltipInfo.$mount()

                        return tooltipInfo.$el
                    }
                }
            }
        },

        symbol_size_function(val) {
            if (!this.chart_settings.sized_by)
                return 12;

            val = ((val / this.max_size) * 100).toFixed(0)

            val = val * 0.4

            if(val < 10)
              val = 10

            return  val


            if (!this.chart_settings.sized_by)
                return 12;
            if (!val) val = 0;
            if (this.max_size < 10)
                val *= 10000000
            else if (this.max_size < 100)
                val *= 1000000
            else if (this.max_size < 1000)
                val *= 100000
            else if (this.max_size < 10000)
                val *= 10000
            else if (this.max_size < 100000)
                val *= 1000
            else if (this.max_size < 1000000)
                val *= 100
            else if (this.max_size < 10000000)
                val *= 10
            return Math.sqrt(val) / 5e2 + 10;
        },

        colorize_function(isins_arr, dimension){
            if (this.chart_settings.colored_by) {
                return [{
                    type: 'continuous',
                    dimension: this.color_index,
                    orient: 'vertical',
                    right: 15,
                    bottom: 55,
                    min: this.min_color,
                    max: this.max_color,
                    text: ['HIGH', 'LOW'],
                    calculable: true,
                    inRange: {
                        color: ['#FF0000', '#00FF00']
                    }
                }]
            }
            else {
                return [
                    // {
                    //     show: false,
                    //     dimension: dimension,
                    //     categories: isins_arr,
                    //     color: '#51689b'
                    //     // inRange: {
                    //     //     color: (function () {
                    //     //         var colors = ['#51689b', '#ce5c5c', '#fbc357', '#8fbf8f', '#659d84', '#fb8e6a', '#c77288', '#786090', '#91c4c5', '#6890ba'];
                    //     //         return colors.concat(colors);
                    //     //     })()
                    //     // }
                    // }
                ]
            }
        }
    },
    watch: {
        current_theme(){
            this.make_chart();
        },
    }
}
</script>
